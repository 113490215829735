
import { Component, Ref, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import CompanyContactForm from '@/pages/CompanyContact/components/CompanyContactForm.vue'
import CompanyContact from '@/models/CompanyContact'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    CompanyContactForm,
    Widget,
    FooterNav,
  },
})
export default class CompanyContactEdit extends ViewModel {
  @Ref() readonly validator!: any

  public contact: CompanyContact= new CompanyContact()

  public loading: boolean = true

  public ready: boolean = false

  public ref: any = 'company-contacts'

  public mounted() {
    if (this.$route.params.id && this.$route.params.id.length > 0) {
      this.loadContact()
    } else {
      this.contact = new CompanyContact()
      this.loading = false
      this.ready = true
    }
  }

  public created() {
    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }
  }

  public loadContact() {
    this.loading = true
    CompanyContact.find(this.$route.params.id)
      .then((response: any) => {
        this.contact = response
        this.loading = false

        this.ready = true
      })
      .catch((error: any) => {
        this.loading = false
        this.ready = true
      })
  }

  public submit() {
    this.loading = true
    this.contact
      .save()
      .then((response: any) => {
        this.$router.push({ name: this.ref })
      })
      .catch((error: any) => {
        this.loading = false
      })
  }
}
